@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Expletus Sans', sans-serif;
  color: #333;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(250, 250, 250, 1) 100%);
  position: relative; /* Ensure the pseudo-element is positioned correctly */
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(250, 250, 250, 0.5) 100%);
  pointer-events: none;
  z-index: -1;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}

