.profileContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  position: relative;
  margin-top: 1rem;
}

.earningsOverview {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.earningsOverview h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 16px;
}

.avatarContainer {
  position: absolute;
  top: 24px;
  right: 24px;
  text-align: center;
}

.avatarWrapper {
  position: relative;
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.avatarIcon {
  width: 100%;
  height: 100%;
  color: #6c757d;
}

.walletSection {
  margin-top: 32px;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.walletSection .addWalletButton {
  margin-right: 16px;
}

.walletDisplay {
  min-width: 0;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 16px;
  text-align: center;
}

.notificationToggle {
  color: #343a40;
  margin: 32px 0;
  padding: 8px 0;
}

.avatarName {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
}

.editAvatarIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: #34A853;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
}

.editAvatarIcon span {
  color: white;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
}

.sandIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
}

.subtext {
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 8px;
}

.earningsMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.totalEarnings {
  font-size: 36px;
  font-weight: bold;
  color: #333;
}

.earningsInfo {
  display: flex;
  flex-direction: column;
}

.earningsChange {
  color: #155724;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 4px;
}

.weeklyEarnings {
  color: #28a745;
  font-size: 14px;
}

.progressBar {
  display: flex;
  height: 30px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
}

.adminActions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.resetButton {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.resetButton:hover {
  background-color: #e60000;
}

.progressSegment {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.gameA {
  background-color: #4285F4;
}

.gameB {
  background-color: #34A853;
}

.gameC {
  background-color: #FBBC05;
}

.gameD {
  background-color: #EA4335;
}

.gameDetails {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 24px;
}

.gameItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gameName {
  font-weight: bold;
  margin-bottom: 4px;
}

.gameName.gameA {
  color: #4285F4;
}

.gameName.gameB {
  color: #34A853;
}

.gameName.gameC {
  color: #FBBC05;
}

.gameName.gameD {
  color: #EA4335;
}

.gameStats {
  color: #6c757d;
  text-align: right;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
}

.gameStats span {
  display: flex;
  align-items: center;
}

.statsContainer {
  display: flex;
  justify-content: space-between;
}

.statValue {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
}

.avgEarnings {
  color: #007bff;
}

.statChange {
  color: #6c757d;
  font-size: 14px;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.addWalletButton {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 12px; 
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 42px;
  flex-shrink: 0;
}

.addWalletButton:hover {
  background-color: #3B78DB;
}

.walletDisplay {
  flex: 1; 
  font-size: 14px;
  background-color: #f5f5f5;
  padding: 12px 16px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adminActions {
  margin-top: 20px;
}

.adminActions button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.walletInput {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.errorInput {
  border-color: #dc3545;
}

.errorText {
  color: #dc3545;
  font-size: 14px;
  margin: 4px 0 12px;
  padding: 0;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.acceptButton {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.acceptButton:hover {
  background-color: #3B78DB;
}

.walletInput {
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.closeButton {
  color: #000;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

.history {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #e0e0e0;
}

.historyTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #333;
}

.bc2Earnings {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.bc2Amount {
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.bc2Note {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.historyContainer {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-top: 24px;
}

.historyTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 16px;
}

.historyItem {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.historyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.historyLabel {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.historyAmount {
  font-size: 18px;
  font-weight: 600;
  color: #34A853;
  display: flex;
  align-items: center;
}

.historyAmount .sandIcon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.historyNote {
  color: #666;
  font-size: 14px;
  margin: 0;
}

@media (min-width: 768px) {
  .gameDetails {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .profileContainer {
    max-width: 100%;
    padding: 1rem;
    box-shadow: none;
    border-radius: 0;
  }

  .walletDisplay {
    font-size: 14px;
    color: #000;
    margin: 0px auto;
  }

  .earningsOverview {
    padding: 16px;
    margin-bottom: 16px;
  }

  .earningsOverview h2 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .avatarContainer {
    top: 16px;
    right: 16px;
  }

  .avatar, .avatarIcon {
    width: 60px;
    height: 60px;
    font-size: 60px;
  }

  .avatarName {
    font-size: 12px;
    margin-top: 4px;
  }

  .sandIcon {
      width: 16px;
      height: 16px;
      margin: 0 4px;
      vertical-align: middle;
  }

  .timeSection {
      margin-right: 8px;
  }

  .subtext {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .totalEarnings {
    font-size: 28px;
  }

  .progressBar {
    height: 30px;
    margin-bottom: 12px;
  }

  .progressSegment {
    height: 30px;
    font-size: 12px;
  }

  .progressSegment:first-child {
    border-radius: 12px 0 0 12px;
  }

  .progressSegment:last-child {
    border-radius: 0 12px 12px 0;
  }

  .gameDetails {
    gap: 12px;
    margin-bottom: 16px;
  }

  .gameName {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .statValue {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .bottomContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }

  .addWalletButton {
    padding: 8px 16px;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .resetButton {
    padding: 8px 16px;
    font-size: 14px;
  }

  .modal {
    width: 90%;
    max-width: 300px;
    padding: 16px;
    z-index: 10000;
  }

  .acceptButton {
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 16px;
  }

  .walletInput {
    padding: 8px;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .closeButton {
    font-size: 16px;
  }

  .avatarWrapper {
    width: 60px;
    height: 60px;
  }

  .editAvatarIcon {
    width: 14px;
    height: 14px;
  }

  .editAvatarIcon span {
    font-size: 12px;
  }
}