.authContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: transparent; /* Removed background color */
}

.authTitle {
  font-size: 2rem;
  color: #000;
  margin-bottom: 1rem;
}

.authForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f9; /* Added white background to form */
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.authInput {
  margin: 0.5rem 0;
  padding: 0.75rem;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

.authButton {
  margin: 0.5rem 0;
  padding: 0.75rem 1.5rem;
  background-color: #2B2B2B; 
  border: 4px solid #2B2B2B;
  border-radius: 13px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Expletus Sans', sans-serif;
  font-weight: 800;
}

.authButton:hover {
  background-color: #1e1e1e;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLink {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
