.eventsAdminContainer {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
}

.spinner {
    font-size: 2rem;
    color: #34A853;
    margin-bottom: 1rem;
}

.createEventSection,
.currentEventsSection {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
}

.sectionTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.titleIcon {
    color: #34A853;
}

.sandboxInfo {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 2rem;
}

.sandboxInfo h3 {
    font-size: 1.1rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.sandboxInfo p {
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
}

.eventForm {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.formGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.formGroup label {
    font-weight: 500;
    color: #2c3e50;
}

.input,
.textarea {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.9rem;
    transition: border-color 0.2s;
    background-color: rgba(255, 255, 255, 0.9);
}

.input:focus,
.textarea:focus {
    border-color: #34A853;
    outline: none;
}

.textarea {
    resize: vertical;
    min-height: 100px;
}

.buttonGroup {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.completeRiddleButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.completeRiddleButton:hover {
    background-color: #218838;
}

.participantsModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modalContent h3 {
    margin: 0;
    color: #2c3e50;
    font-size: 1.5rem;
}

.searchBox {
    position: relative;
    display: flex;
    align-items: center;
}

.searchBox svg {
    position: absolute;
    left: 1rem;
    color: #6c757d;
}

.searchBox input {
    width: 100%;
    padding: 0.75rem;
    padding-left: 2.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.9rem;
}

.participantsList {
    overflow-y: auto;
    max-height: 300px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
}

.participantItem {
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid #e2e8f0;
}

.participantItem:last-child {
    border-bottom: none;
}

.participantItem:hover {
    background-color: #f8f9fa;
}

.participantItem.selected {
    background-color: #e9ecef;
}

.modalActions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 1rem;
}

.cancelButton {
    padding: 0.5rem 1rem;
    border: 1px solid #6c757d;
    background: white;
    color: #6c757d;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
}

.cancelButton:hover {
    background: #6c757d;
    color: white;
}

.completeButton {
    padding: 0.5rem 1rem;
    border: none;
    background: #28a745;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.completeButton:hover {
    background: #218838;
}

.completeButton:disabled {
    background: #6c757d;
    cursor: not-allowed;
}

.testButton,
.submitButton,
.deleteButton {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
}

.testButton {
    background: #6c757d;
    color: white;
    flex: 1;
}

.testButton:hover {
    background: #5a6268;
}

.submitButton {
    background: #34A853;
    color: white;
    flex: 2;
}

.submitButton:hover {
    background: #28a745;
}

.deleteButton {
    background: #dc3545;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.deleteButton:hover {
    background: #c82333;
}

.eventsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
}

.participantsModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
}

.searchBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #f5f5f5;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.searchBox input {
    flex: 1;
    border: none;
    background: none;
    outline: none;
    font-size: 1rem;
}

.participantsList {
    max-height: 400px;
    overflow-y: auto;
    margin: 1rem 0;
}

.participantItem {
    padding: 0.75rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.participantItem:hover {
    background-color: #f5f5f5;
}

.participantItem.selected {
    background-color: #34A853;
    color: white;
}

.modalActions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.cancelButton {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: 1px solid #ddd;
    background: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.cancelButton:hover {
    background-color: #f5f5f5;
}

.completeButton {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: none;
    background: #34A853;
    color: white;
    cursor: pointer;
    transition: opacity 0.2s;
}

.completeButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.completeButton:not(:disabled):hover {
    opacity: 0.9;
}

.eventCard {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #e2e8f0;
    transition: transform 0.2s, box-shadow 0.2s;
}

.eventCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.eventCard h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.description {
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.eventDetails {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.eventDetails p {
    font-size: 0.9rem;
    color: #4a5568;
}

.eventDetails strong {
    color: #34A853;
}

.noEventsMessage {
    text-align: center;
    color: #6c757d;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    font-size: 1.1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .buttonGroup {
        flex-direction: column;
    }

    .testButton,
    .submitButton {
        width: 100%;
    }

    .eventsGrid {
        grid-template-columns: 1fr;
    }
}
