/* Review.module.css */
.reviewContainer {
  padding: 1rem;
  max-width: 100%;
  overflow-x: hidden;
}

.reviewTitle {
  font-size: 1.8rem;
  color: #000;
  margin-bottom: 1rem;
  font-family: 'Expletus Sans', sans-serif;
  font-weight: 800;
  text-align: center;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.tabButton {
  padding: 8px 16px;
  margin: 5px;
  border: none;
  background-color: #2B2B2B;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  font-family: 'Expletus Sans', sans-serif;
  font-weight: 800;
  font-size: 0.9rem;
}

.tabButton.active {
  background-color: #1e1e1e;
}

.searchContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.searchInput {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
}

.searchButton {
  padding: 10px 20px;
  background-color: #2B2B2B;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Expletus Sans', sans-serif;
  font-weight: 600;
}

/* Table styles for desktop */
.reviewTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.reviewTable th,
.reviewTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.reviewTable th {
  background-color: #2B2B2B;
  color: white;
}

.thumbnail {
  width: 100px;
  height: auto;
  cursor: pointer;
}

/* Button styles */
.button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  color: white;
  font-family: 'Expletus Sans', sans-serif;
  font-weight: 800;
  border-radius: 8px;
  font-size: 0.9rem;
  margin-right: 5px;
}

.approve {
  background-color: green;
}

.reject {
  background-color: red;
}

.mark {
  background-color: yellow;
  color: black;
}

.statusApproved {
  color: green;
  font-weight: bold;
}

.statusRejected {
  color: red;
  font-weight: bold;
}

.loadMoreButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #2B2B2B;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Expletus Sans', sans-serif;
  font-weight: 600;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 90%;
  max-width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.largeImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noScores {
  text-align: center;
  font-family: 'Expletus Sans', sans-serif;
  color: #666;
}

/* Mobile styles */
@media (max-width: 767px) {
  .reviewTable {
    display: none;
  }

  .mobileScoresList {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobileScoreItem {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
  }

  .mobileScoreDetails p {
    margin: 5px 0;
    font-family: 'Expletus Sans', sans-serif;
    font-size: 0.9rem;
  }

  .mobileScoreMedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .mobileThumbnail {
    width: 100%;
    max-width: 300px;
    height: auto;
    cursor: pointer;
    border-radius: 4px;
  }

  .mobileVideoLink {
    margin-top: 10px;
    color: #2B2B2B;
    text-decoration: none;
    font-weight: bold;
  }

  .mobileScoreActions {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .reviewContainer {
    padding: 2rem;
  }

  .reviewTitle {
    font-size: 2.5rem;
  }

  .tabButton {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .mobileScoresList {
    display: none;
  }
  .modal {
    padding: 10px;
  }

  .closeButton {
    top: 5px;
    right: 5px;
    font-size: 20px;
    width: 30px;
    height: 30px;
  }
}