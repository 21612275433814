/* NavbarFramer.module.css */
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.wrapper {
    width: 100%;
    height: 64px;
    position: relative;
}

.nav {
    box-sizing: border-box;
    width: 1200px;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 1000;
}

.navCenter {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 24px;
    width: auto;
    cursor: pointer;
}

.navLink {
    color: #333;
    text-decoration: none;
    font-family: 'Expletus Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px; 
    letter-spacing: 0; 
    transition: color 0.2s;
    cursor: pointer;      
    user-select: none;    
    display: inline;      
    -webkit-font-smoothing: antialiased;  
    -moz-osx-font-smoothing: grayscale;   
    text-rendering: optimizeLegibility;   
}  

.navLink:hover {
    color: #000;
    text-decoration: underline;
}

.navLink:focus {
    outline: none;
    text-decoration: underline; /* or none, depending on your preference */
}

.separator {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    z-index: 1000;
}

/* Media queries remain the same */
@media (max-width: 1200px) {
    .nav {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .navCenter {
        gap: 16px;
        padding: 0 16px;
    }
}